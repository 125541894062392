/* You can add global styles to this file, and also import other style files */
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";
// @import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
// @import "~@angular/material/prebuilt-themes/purple-green.css";

@import "./assets/theme/colors";
@import "./assets/theme/fonts";
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";
// @import "@angular/cdk/overlay-prebuilt.css";

div#root {
  height: 100%;
}

html,
body {
  font-family: "Satoshi-Regular", sans-serif;
  font-size: 17px;
  height: 100%;
  width: 100vw;
  line-height: 1.4;
  min-height: 100vh;
  background-color: $viu-white !important;
  scroll-behavior: smooth;
  margin: 0px;
}


.content-title-text {
  font-weight: 700;
  letter-spacing: -0.01em;
  font-size: 24px;
  line-height: 32px;
  color: #0c2225;
}

.semi-title-text {
  font-size: 15px;
  color: #626e77 !important;
  line-height: 22px;
}

.small-text {
  font-size: 12px;
  line-height: 18px;
}


.text-semi-black-gray {
  color: #626e77 !important;
}




// TABLES

.table thead th {
  vertical-align: bottom;
  border-top: 1px solid #dee2e6;
  border-bottom: none;
  font-size: 15px;
  line-height: 22px;
  color: #626e77;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
}

tr {
  border-bottom: 1px solid #e1e5e8;
}

th {
  border-bottom: none;
}

td {
  height: 50px;
  color: #0c2225;
  font-size: 15px;
  line-height: 22px;
  font-weight: 700;
}




@media screen and (max-width: 600px) {
  table {
    border: 0;
    background-color: #f7f7f7;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    display: block;
    margin-bottom: .625em;
  }

  table td {
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

.modal {
  background: rgba(12, 34, 37, 0.95);
}

.modal-content {
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent !important;
}

nav .nav-tabs  a{
  color: inherit;
}
nav .nav-tabs  a:hover{
  color: inherit;
}
